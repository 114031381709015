import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { API_URL } from '../api-url.token';
import { GetStatsPayload, GetStatsResponse } from './get-stats-payload';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  http = inject(HttpClient);
  apiUrl = inject(API_URL);

  /*
      {
          "code": 200,
          "result": {
              "affiliateCount": 0,
              "brokerCount": 1,
              "userCount": 11,
              "lenderCount": 0,
              "leadCount": 0,
              "applicationCount": 0,
              "funderCount": 0,
          },
          "links": [
              {
                  "rel": "self",
                  "href": "https://localhost:5000/api/stats?getAffiliateCount=true&getBrokerCount=true&getUserCount=true&getLenderCount=false",
                  "method": "GET"
              }
          ]
      }
  */
  getCounts(payload: GetStatsPayload) {
    // console.log('getCounts(); payload=', payload);
    const params = new HttpParams({ fromObject: payload });
    return this.http.get<GetStatsResponse>(`${this.apiUrl}/api/stats/counts`, { params });
  }
}
